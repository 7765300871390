export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Address = {
  __typename?: 'Address';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  formFields?: Maybe<Array<AddressFormField>>;
};

export type AddressFormField = {
  __typename?: 'AddressFormField';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Aggregated = {
  __typename?: 'Aggregated';
  availableToSell: Scalars['Long']['output'];
  warningLevel: Scalars['Int']['output'];
};

export type AggregatedInventory = {
  __typename?: 'AggregatedInventory';
  availableToSell: Scalars['Int']['output'];
  warningLevel: Scalars['Int']['output'];
};

export type Banner = Node & {
  __typename?: 'Banner';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  path: Scalars['String']['output'];
  campaignFilter?: Maybe<BannerCampaignFilter>;
  imageUrl: Scalars['String']['output'];
  fromDate?: Maybe<Scalars['DateTime']['output']>;
  toDate?: Maybe<Scalars['DateTime']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  showOnStartpage?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<BannerLocation>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  products?: Maybe<ProductConnection>;
};


export type BannerProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BannerCampaignFilter = {
  __typename?: 'BannerCampaignFilter';
  attributeName: Scalars['String']['output'];
  attributeValue: Scalars['String']['output'];
  searchFilters?: Maybe<Scalars['String']['output']>;
};

export enum BannerLocation {
  Top = 'TOP',
  Bottom = 'BOTTOM'
}

export type BannerConnection = {
  __typename?: 'BannerConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
};

export type BannerEdge = {
  __typename?: 'BannerEdge';
  node: Banner;
  cursor?: Maybe<Scalars['String']['output']>;
};

export type Brand = Node & {
  __typename?: 'Brand';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  defaultImage?: Maybe<Image>;
  description?: Maybe<Scalars['String']['output']>;
  pageTitle: Scalars['String']['output'];
  metaDesc: Scalars['String']['output'];
  metaKeywords: Array<Scalars['String']['output']>;
  searchKeywords: Array<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  products: ProductConnection;
  metafields: MetafieldConnection;
};


export type BrandProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type BrandMetafieldsArgs = {
  namespace: Scalars['String']['input'];
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  node: Brand;
  cursor: Scalars['String']['output'];
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbConnection = {
  __typename?: 'BreadcrumbConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<BreadcrumbEdge>>>;
};

export type BreadcrumbEdge = {
  __typename?: 'BreadcrumbEdge';
  node: Breadcrumb;
  cursor: Scalars['String']['output'];
};

export type BulkPricingFixedPriceDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingFixedPriceDiscount';
  price: Scalars['BigDecimal']['output'];
  minimumQuantity: Scalars['Int']['output'];
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
};

export type BulkPricingPercentageDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingPercentageDiscount';
  percentOff: Scalars['BigDecimal']['output'];
  minimumQuantity: Scalars['Int']['output'];
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
};

export type BulkPricingRelativePriceDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingRelativePriceDiscount';
  priceAdjustment: Scalars['BigDecimal']['output'];
  minimumQuantity: Scalars['Int']['output'];
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
};

export type BulkPricingTier = {
  minimumQuantity: Scalars['Int']['output'];
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
};

export type BundleItem = {
  __typename?: 'BundleItem';
  variantId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Money;
  path: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  defaultImageUrl: Scalars['String']['output'];
  options: Array<Maybe<SelectedVariantOption>>;
};

export type Bundle = {
  __typename?: 'Bundle';
  unbundledPrice: Money;
  totalSavings: Money;
  items?: Maybe<Array<Maybe<BundleItem>>>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  amount: Scalars['BigDecimal']['output'];
};

export enum ProductStorageClimate {
  Ambient = 'ambient',
  Chilled = 'chilled',
  Frozen = 'frozen',
  RoomTemp = 'roomTemp'
}

export type Cart = Node & {
  __typename?: 'Cart';
  id: Scalars['ID']['output'];
  metaFields?: Maybe<Array<Maybe<CartMetaField>>>;
  items: Array<CartItem>;
  amount?: Maybe<Money>;
  baseAmount?: Maybe<Money>;
  campaignDiscountAmount?: Maybe<Money>;
  promoDiscountAmount?: Maybe<Money>;
  promo?: Maybe<Promo>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  containsVetFood?: Maybe<Scalars['Boolean']['output']>;
  storageClimates: Array<Maybe<ProductStorageClimate>>;
  frozenItemsHandlingFee?: Maybe<Money>;
};

export type CartItem = Node & {
  __typename?: 'CartItem';
  id: Scalars['ID']['output'];
  defaultImageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  prices: VariantPrices;
  options?: Maybe<Array<Maybe<SelectedVariantOption>>>;
  productId: Scalars['Int']['output'];
  variantId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  categories: Array<Category>;
  primaryBreadcrumb?: Maybe<Scalars['String']['output']>;
  animalType?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Brand>;
  campaign?: Maybe<Campaign>;
  sku: Scalars['String']['output'];
  type?: Maybe<ProductType>;
  storageClimate: ProductStorageClimate;
};


export type CartItemPricesArgs = {
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type CartItemInput = {
  productId?: InputMaybe<Scalars['Int']['input']>;
  variantId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartMetaField = {
  __typename?: 'CartMetaField';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  namespace: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CatalogProductOption = {
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type CatalogProductOptionValue = {
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type Category = Node & {
  __typename?: 'Category';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  defaultImage?: Maybe<Image>;
  description: Scalars['String']['output'];
  animal?: Maybe<Scalars['String']['output']>;
  breadcrumbs: BreadcrumbConnection;
  products: ProductConnection;
  pageTitle: Scalars['String']['output'];
  metaDesc: Scalars['String']['output'];
  metafields: MetafieldConnection;
};


export type CategoryBreadcrumbsArgs = {
  depth: Scalars['Int']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CategoryMetafieldsArgs = {
  namespace: Scalars['String']['input'];
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  node: Category;
  cursor: Scalars['String']['output'];
};

export type CategoryTreeItem = {
  __typename?: 'CategoryTreeItem';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  menu?: Maybe<Scalars['Boolean']['output']>;
  children: Array<CategoryTreeItem>;
};

export type CheckboxOption = CatalogProductOption & {
  __typename?: 'CheckboxOption';
  checkedByDefault: Scalars['Boolean']['output'];
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type ContactField = {
  __typename?: 'ContactField';
  address: Scalars['String']['output'];
  country: Scalars['String']['output'];
  addressType: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export enum CurrencyCode {
  Adp = 'ADP',
  Aed = 'AED',
  Afa = 'AFA',
  Afn = 'AFN',
  Alk = 'ALK',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Aok = 'AOK',
  Aon = 'AON',
  Aor = 'AOR',
  Ara = 'ARA',
  Arl = 'ARL',
  Arm = 'ARM',
  Arp = 'ARP',
  Ars = 'ARS',
  Ats = 'ATS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azm = 'AZM',
  Azn = 'AZN',
  Bad = 'BAD',
  Bam = 'BAM',
  Ban = 'BAN',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bec = 'BEC',
  Bef = 'BEF',
  Bel = 'BEL',
  Bgl = 'BGL',
  Bgm = 'BGM',
  Bgn = 'BGN',
  Bgo = 'BGO',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bol = 'BOL',
  Bop = 'BOP',
  Bov = 'BOV',
  Brb = 'BRB',
  Brc = 'BRC',
  Bre = 'BRE',
  Brl = 'BRL',
  Brn = 'BRN',
  Brr = 'BRR',
  Brz = 'BRZ',
  Bsd = 'BSD',
  Btn = 'BTN',
  Buk = 'BUK',
  Bwp = 'BWP',
  Byb = 'BYB',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Cle = 'CLE',
  Clf = 'CLF',
  Clp = 'CLP',
  Cnx = 'CNX',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Csd = 'CSD',
  Csk = 'CSK',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Ddm = 'DDM',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Ecv = 'ECV',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esa = 'ESA',
  Esb = 'ESB',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghc = 'GHC',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gns = 'GNS',
  Gqe = 'GQE',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gwe = 'GWE',
  Gwp = 'GWP',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrd = 'HRD',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ilp = 'ILP',
  Ilr = 'ILR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Isj = 'ISJ',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krh = 'KRH',
  Kro = 'KRO',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Ltt = 'LTT',
  Luc = 'LUC',
  Luf = 'LUF',
  Lul = 'LUL',
  Lvl = 'LVL',
  Lvr = 'LVR',
  Lyd = 'LYD',
  Mad = 'MAD',
  Maf = 'MAF',
  Mcf = 'MCF',
  Mdc = 'MDC',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mgf = 'MGF',
  Mkd = 'MKD',
  Mkn = 'MKN',
  Mlf = 'MLF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mtp = 'MTP',
  Mur = 'MUR',
  Mvp = 'MVP',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxp = 'MXP',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mze = 'MZE',
  Mzm = 'MZM',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nic = 'NIC',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pei = 'PEI',
  Pen = 'PEN',
  Pes = 'PES',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rhd = 'RHD',
  Rol = 'ROL',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sdg = 'SDG',
  Sdp = 'SDP',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Srg = 'SRG',
  Ssp = 'SSP',
  Std = 'STD',
  Sur = 'SUR',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tjs = 'TJS',
  Tmm = 'TMM',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Tpe = 'TPE',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Uak = 'UAK',
  Ugs = 'UGS',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uss = 'USS',
  Uyi = 'UYI',
  Uyp = 'UYP',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Vnn = 'VNN',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xeu = 'XEU',
  Xfo = 'XFO',
  Xfu = 'XFU',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xre = 'XRE',
  Ydd = 'YDD',
  Yer = 'YER',
  Yud = 'YUD',
  Yum = 'YUM',
  Yun = 'YUN',
  Yur = 'YUR',
  Zal = 'ZAL',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zmw = 'ZMW',
  Zrn = 'ZRN',
  Zrz = 'ZRZ',
  Zwd = 'ZWD',
  Zwl = 'ZWL',
  Zwr = 'ZWR'
}

export type Customer = {
  __typename?: 'Customer';
  entityId: Scalars['Int']['output'];
  company: Scalars['String']['output'];
  customerGroupId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  taxExemptCategory: Scalars['String']['output'];
  addressCount: Scalars['Int']['output'];
  attributeCount: Scalars['Int']['output'];
  storeCredit: Array<Money>;
  attributes: CustomerAttributes;
};

export type CustomerAttribute = {
  __typename?: 'CustomerAttribute';
  entityId: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CustomerAttributes = {
  __typename?: 'CustomerAttributes';
  attribute: CustomerAttribute;
};


export type CustomerAttributesAttributeArgs = {
  entityId: Scalars['Int']['input'];
};

export type CustomField = {
  __typename?: 'CustomField';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomFieldConnection = {
  __typename?: 'CustomFieldConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CustomFieldEdge>>>;
};

export type CustomFieldEdge = {
  __typename?: 'CustomFieldEdge';
  node: CustomField;
  cursor: Scalars['String']['output'];
};

export type DateFieldOption = CatalogProductOption & {
  __typename?: 'DateFieldOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type DateTimeExtended = {
  __typename?: 'DateTimeExtended';
  utc: Scalars['DateTime']['output'];
};

export type DisplayField = {
  __typename?: 'DisplayField';
  shortDateFormat: Scalars['String']['output'];
  extendedDateFormat: Scalars['String']['output'];
};

export type DistanceFilter = {
  radius: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  lengthUnit: LengthUnit;
};

export type FileUploadFieldOption = CatalogProductOption & {
  __typename?: 'FileUploadFieldOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String']['output'];
  urlOriginal: Scalars['String']['output'];
  altText: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};


export type ImageUrlArgs = {
  width: Scalars['Int']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ImageEdge>>>;
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  node: Image;
  cursor: Scalars['String']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  locations: LocationConnection;
};


export type InventoryLocationsArgs = {
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  typeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  distanceFilter?: InputMaybe<DistanceFilter>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryByLocations = {
  __typename?: 'InventoryByLocations';
  locationEntityId: Scalars['Long']['output'];
  availableToSell: Scalars['Long']['output'];
  warningLevel: Scalars['Int']['output'];
  isInStock: Scalars['Boolean']['output'];
};

export enum LengthUnit {
  Miles = 'Miles',
  Kilometres = 'Kilometres'
}

export type LocationConnection = {
  __typename?: 'LocationConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  node: InventoryByLocations;
  cursor: Scalars['String']['output'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  result: Scalars['String']['output'];
};

export type LogoField = {
  __typename?: 'LogoField';
  title: Scalars['String']['output'];
  image: Image;
};

export type LogoutResult = {
  __typename?: 'LogoutResult';
  result: Scalars['String']['output'];
};

export type Measurement = {
  __typename?: 'Measurement';
  value: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type MetafieldConnection = {
  __typename?: 'MetafieldConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<MetafieldEdge>>>;
};

export type MetafieldEdge = {
  __typename?: 'MetafieldEdge';
  node: Metafields;
  cursor: Scalars['String']['output'];
};

export type Metafields = {
  __typename?: 'Metafields';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String']['output'];
  value: Scalars['BigDecimal']['output'];
};

export type MoneyRange = {
  __typename?: 'MoneyRange';
  min: Money;
  max: Money;
};

export type MultiLineTextFieldOption = CatalogProductOption & {
  __typename?: 'MultiLineTextFieldOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type MultipleChoiceOption = CatalogProductOption & {
  __typename?: 'MultipleChoiceOption';
  displayStyle: Scalars['String']['output'];
  values: ProductOptionValueConnection;
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};


export type MultipleChoiceOptionValuesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MultipleChoiceOptionValue = CatalogProductOptionValue & {
  __typename?: 'MultipleChoiceOptionValue';
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCart?: Maybe<Cart>;
  addCartItems?: Maybe<Cart>;
  updateCartItem?: Maybe<Cart>;
  deleteCartItem?: Maybe<Cart>;
  deleteCart?: Maybe<Scalars['Boolean']['output']>;
  login: LoginResult;
  logout: LogoutResult;
  cancelSubscription?: Maybe<Scalars['Boolean']['output']>;
  skipSubscriptionShipment?: Maybe<Scalars['Boolean']['output']>;
  pauseSubscription?: Maybe<Subscription>;
  resumeSubscription?: Maybe<ResumeSubscriptionResult>;
  addProductReview?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationCreateCartArgs = {
  cartItems?: InputMaybe<Array<CartItemInput>>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCartItemsArgs = {
  id: Scalars['ID']['input'];
  cartItems?: InputMaybe<Array<CartItemInput>>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCartItemArgs = {
  id: Scalars['ID']['input'];
  cartItemId: Scalars['ID']['input'];
  cartItem: CartItemInput;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteCartItemArgs = {
  id: Scalars['ID']['input'];
  cartItemId: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteCartArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID']['input'];
  reason: SubscriptionCancellationReason;
};


export type MutationSkipSubscriptionShipmentArgs = {
  subscriptionShipmentId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};


export type MutationPauseSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResumeSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddProductReviewArgs = {
  productEntityId: Scalars['Int']['input'];
  review: ProductReviewInput;
};

export type ResumeSubscriptionResult = {
  __typename?: 'ResumeSubscriptionResult';
  subscription?: Maybe<Subscription>;
  nextShipmentDate?: Maybe<Scalars['DateTime']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NumberFieldOption = CatalogProductOption & {
  __typename?: 'NumberFieldOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type OptionConnection = {
  __typename?: 'OptionConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<OptionEdge>>>;
};

export type OptionEdge = {
  __typename?: 'OptionEdge';
  node: ProductOption;
  cursor: Scalars['String']['output'];
};

export type OptionValueConnection = {
  __typename?: 'OptionValueConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<OptionValueEdge>>>;
};

export type OptionValueEdge = {
  __typename?: 'OptionValueEdge';
  node: ProductOptionValue;
  cursor: Scalars['String']['output'];
};

export type OptionValueId = {
  optionEntityId: Scalars['Int']['input'];
  valueEntityId: Scalars['Int']['input'];
};

export type Order = Node & {
  __typename?: 'Order';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  status?: Maybe<OrderStatus>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  total?: Maybe<Money>;
  subtotal?: Maybe<Money>;
  discountAmount?: Maybe<Money>;
  shippingCost?: Maybe<Money>;
  handlingCost?: Maybe<Money>;
  billingAddress: Address;
  shippingAddress?: Maybe<Address>;
  shippingService?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  returnShippingService: Scalars['String']['output'];
  orderLines?: Maybe<Array<OrderLine>>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  node: Order;
  cursor: Scalars['String']['output'];
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
  variantId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prices: OrderLinePrices;
  total: Money;
  baseTotal?: Maybe<Money>;
  quantity: Scalars['Int']['output'];
  quantityRefunded: Scalars['Int']['output'];
  quantityNotRefunded: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  productUrl?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<SimpleBrand>;
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  options: Array<SelectedVariantOption>;
  isRefundable?: Maybe<Scalars['Boolean']['output']>;
  isPrescriptionBag: Scalars['Boolean']['output'];
  currentProductStatus?: Maybe<OrderLineCurrentProductStatus>;
  subscription?: Maybe<Subscription>;
};

export type OrderLineCurrentProductStatus = {
  __typename?: 'OrderLineCurrentProductStatus';
  missingProduct: Scalars['Boolean']['output'];
  missingVariant: Scalars['Boolean']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isPurchasable?: Maybe<Scalars['Boolean']['output']>;
};

export type OrderLinePrices = Prices & {
  __typename?: 'OrderLinePrices';
  price: Money;
  salePrice?: Maybe<Money>;
  basePrice?: Maybe<Money>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
  perPage?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PriceRanges = {
  __typename?: 'PriceRanges';
  priceRange: MoneyRange;
  retailPriceRange?: Maybe<MoneyRange>;
};

export type Prices = {
  price: Money;
  salePrice?: Maybe<Money>;
  basePrice?: Maybe<Money>;
};

export type Product = Node & {
  __typename?: 'Product';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  path: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  name: Scalars['String']['output'];
  description: Scalars['String']['output'];
  plainTextDescription: Scalars['String']['output'];
  excerpt?: Maybe<Scalars['String']['output']>;
  warranty: Scalars['String']['output'];
  minPurchaseQuantity?: Maybe<Scalars['Int']['output']>;
  maxPurchaseQuantity?: Maybe<Scalars['Int']['output']>;
  addToCartUrl: Scalars['String']['output'];
  addToWishlistUrl: Scalars['String']['output'];
  campaign?: Maybe<Campaign>;
  prices?: Maybe<ProductPrices>;
  /** @deprecated Use priceRanges inside prices node instead. */
  priceRanges?: Maybe<PriceRanges>;
  weight?: Maybe<Measurement>;
  height?: Maybe<Measurement>;
  width?: Maybe<Measurement>;
  depth?: Maybe<Measurement>;
  options: OptionConnection;
  productOptions: ProductOptionConnection;
  reviewSummary: ProductReviewSummary;
  reviews?: Maybe<ProductReviewConnection>;
  type: ProductType;
  /** @deprecated Use status inside availabilityV2 instead. */
  availability: Scalars['String']['output'];
  /** @deprecated Use description inside availabilityV2 instead. */
  availabilityDescription: Scalars['String']['output'];
  availabilityV2: ProductAvailability;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  categories: CategoryConnection;
  categoryIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  mainCategoryId?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<SimpleBrand>;
  variants: VariantConnection;
  defaultVariant?: Maybe<Variant>;
  customFields: CustomFieldConnection;
  images: ImageConnection;
  defaultImage?: Maybe<Image>;
  relatedProducts?: Maybe<RelatedProductsConnection>;
  inventory: ProductInventory;
  pageTitle: Scalars['String']['output'];
  metaDesc: Scalars['String']['output'];
  metafields: MetafieldConnection;
  videos?: Maybe<Array<Maybe<Video>>>;
  /** @deprecated Alpha version. Do not use in production. */
  createdAt: DateTimeExtended;
  storageClimate: ProductStorageClimate;
  isVetRecommended?: Maybe<Scalars['Boolean']['output']>;
  animalCategories: Array<Maybe<CategoryEdge>>;
  isSubscribable?: Maybe<Scalars['Boolean']['output']>;
  listBadge?: Maybe<ProductListBadge>;
};


export type ProductPlainTextDescriptionArgs = {
  characterLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPricesArgs = {
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode?: InputMaybe<CurrencyCode>;
};


export type ProductPriceRangesArgs = {
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductOptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProductOptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductReviewsArgs = {
  sort?: InputMaybe<ProductReviewsSortInput>;
  filters?: InputMaybe<ProductReviewsFiltersInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCategoriesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  optionValueIds?: InputMaybe<Array<OptionValueId>>;
};


export type ProductCustomFieldsArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductImagesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductRelatedProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductMetafieldsArgs = {
  namespace: Scalars['String']['input'];
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum ProductListBadge {
  HighProteinChicken = 'highProteinChicken',
  SingleProteinFish = 'singleProteinFish'
}

export type ProductAvailability = {
  status: ProductAvailabilityStatus;
  description: Scalars['String']['output'];
};

export enum ProductAvailabilityStatus {
  Available = 'Available',
  Preorder = 'Preorder',
  Unavailable = 'Unavailable'
}

export type ProductAvailable = ProductAvailability & {
  __typename?: 'ProductAvailable';
  status: ProductAvailabilityStatus;
  description: Scalars['String']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  node: Product;
  cursor: Scalars['String']['output'];
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  isInStock: Scalars['Boolean']['output'];
  hasVariantInventory: Scalars['Boolean']['output'];
  aggregated?: Maybe<AggregatedInventory>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
  values: OptionValueConnection;
};


export type ProductOptionValuesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductOptionConnection = {
  __typename?: 'ProductOptionConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductOptionEdge>>>;
};

export type ProductOptionEdge = {
  __typename?: 'ProductOptionEdge';
  node: CatalogProductOption;
  cursor: Scalars['String']['output'];
};

export type ProductOptionValue = {
  __typename?: 'ProductOptionValue';
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type ProductOptionValueConnection = {
  __typename?: 'ProductOptionValueConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductOptionValueEdge>>>;
};

export type ProductOptionValueEdge = {
  __typename?: 'ProductOptionValueEdge';
  node: CatalogProductOptionValue;
  cursor: Scalars['String']['output'];
};

export type ProductPickListOptionValue = CatalogProductOptionValue & {
  __typename?: 'ProductPickListOptionValue';
  productId: Scalars['Int']['output'];
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type ProductPreOrder = ProductAvailability & {
  __typename?: 'ProductPreOrder';
  message?: Maybe<Scalars['String']['output']>;
  willBeReleasedAt?: Maybe<DateTimeExtended>;
  status: ProductAvailabilityStatus;
  description: Scalars['String']['output'];
};

export type ProductPrices = Prices & {
  __typename?: 'ProductPrices';
  price: Money;
  salePrice?: Maybe<Money>;
  basePrice?: Maybe<Money>;
  promoPrice?: Maybe<Money>;
  pricePerKilo?: Maybe<Money>;
  pricePerLiter?: Maybe<Money>;
  pricePerUnit?: Maybe<Money>;
  isCheapestVariantPrice?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductRecommendation = Node & {
  __typename?: 'ProductRecommendation';
  id: Scalars['ID']['output'];
  journalId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
};

export type ProductReview = {
  __typename?: 'ProductReview';
  entityId: Scalars['Int']['output'];
  author: ProductReviewAuthor;
  title: Scalars['String']['output'];
  text: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  createdAt: DateTimeExtended;
};

export type ProductReviewAuthor = {
  __typename?: 'ProductReviewAuthor';
  name: Scalars['String']['output'];
};

export type ProductReviewConnection = {
  __typename?: 'ProductReviewConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductReviewEdge>>>;
};

export type ProductReviewEdge = {
  __typename?: 'ProductReviewEdge';
  node: ProductReview;
  cursor: Scalars['String']['output'];
};

export type ProductReviewInput = {
  title: Scalars['String']['input'];
  text: Scalars['String']['input'];
  author: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  email: Scalars['String']['input'];
};

export type ProductReviewsFiltersInput = {
  rating?: InputMaybe<ProductReviewsFiltersRatingInput>;
};

export type ProductReviewsFiltersRatingInput = {
  minRating?: InputMaybe<Scalars['Int']['input']>;
  maxRating?: InputMaybe<Scalars['Int']['input']>;
};

export enum ProductReviewsSortInput {
  HighestRating = 'HIGHEST_RATING',
  LowestRating = 'LOWEST_RATING',
  Newest = 'NEWEST',
  Oldest = 'OLDEST'
}

export enum ProductType {
  Digital = 'Digital',
  Physical = 'Physical'
}

export type ProductUnavailable = ProductAvailability & {
  __typename?: 'ProductUnavailable';
  message?: Maybe<Scalars['String']['output']>;
  status: ProductAvailabilityStatus;
  description: Scalars['String']['output'];
};

export type Promo = {
  __typename?: 'Promo';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  appliesTo?: Maybe<PromoAppliesTo>;
  excludedBrands?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  maxUsesPerCustomer?: Maybe<Scalars['Int']['output']>;
  isCombinable?: Maybe<Scalars['Boolean']['output']>;
  minPurchase?: Maybe<Scalars['BigDecimal']['output']>;
};

export type PromoAppliesTo = {
  __typename?: 'PromoAppliesTo';
  entity?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type GetOrdersParams = {
  cartId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPending?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxDateCreated?: InputMaybe<Scalars['String']['input']>;
  maxDateModified?: InputMaybe<Scalars['String']['input']>;
  maxId?: InputMaybe<Scalars['Int']['input']>;
  maxTotal?: InputMaybe<Scalars['Int']['input']>;
  minDateCreated?: InputMaybe<Scalars['String']['input']>;
  minDateModified?: InputMaybe<Scalars['String']['input']>;
  minId?: InputMaybe<Scalars['Int']['input']>;
  minTotal?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  banner?: Maybe<Banner>;
  banners: BannerConnection;
  categoryTree: Array<CategoryTreeItem>;
  categories?: Maybe<CategoryConnection>;
  cart?: Maybe<Cart>;
  brands: BrandConnection;
  products: ProductConnection;
  bestSellingProducts: ProductConnection;
  featuredProducts: ProductConnection;
  variantInventory?: Maybe<VariantInventory>;
  order?: Maybe<Order>;
  orders?: Maybe<OrderConnection>;
  previouslyOrderedProducts?: Maybe<ProductConnection>;
  productRecommendations?: Maybe<ProductConnection>;
  route: Route;
  settings?: Maybe<Settings>;
  customer?: Maybe<Customer>;
  node?: Maybe<Node>;
  /** @deprecated Alpha version. Do not use in production. */
  inventory: Inventory;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionConnection;
};


export type QueryBannerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCategoriesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryCartArgs = {
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBrandsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productEntityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryProductsArgs = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  brand?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  includeSubresources?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBestSellingProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFeaturedProductsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryVariantInventoryArgs = {
  productId: Scalars['Int']['input'];
  variantId: Scalars['Int']['input'];
};


export type QueryOrderArgs = {
  entityId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrdersArgs = {
  params?: InputMaybe<GetOrdersParams>;
};


export type QueryPreviouslyOrderedProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductRecommendationsArgs = {
  journalId: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
};


export type QueryRouteArgs = {
  path: Scalars['String']['input'];
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  entityId: Scalars['Int']['input'];
};


export type QuerySubscriptionsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RelatedProductsConnection = {
  __typename?: 'RelatedProductsConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<RelatedProductsEdge>>>;
};

export type RelatedProductsEdge = {
  __typename?: 'RelatedProductsEdge';
  node: Product;
  cursor: Scalars['String']['output'];
};

export type ProductReviewRatingPercentage = {
  __typename?: 'ProductReviewRatingPercentage';
  rating: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
};

export type ProductReviewSummary = {
  __typename?: 'ProductReviewSummary';
  averageRating: Scalars['Float']['output'];
  numberOfReviews: Scalars['Int']['output'];
  summationOfRatings: Scalars['Int']['output'];
  reviewBarPercentages?: Maybe<Array<ProductReviewRatingPercentage>>;
};

export type Route = {
  __typename?: 'Route';
  node?: Maybe<Node>;
};

export type Settings = {
  __typename?: 'Settings';
  storeName: Scalars['String']['output'];
  storeHash: Scalars['String']['output'];
  status: StorefrontStatusType;
  logo: LogoField;
  contact?: Maybe<ContactField>;
  url: UrlField;
  display: DisplayField;
  channelId: Scalars['Long']['output'];
};

export type SelectedVariantOption = {
  __typename?: 'SelectedVariantOption';
  name?: Maybe<Scalars['String']['output']>;
  nameId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

export type SimpleBrand = {
  __typename?: 'SimpleBrand';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum StorefrontStatusType {
  Launched = 'LAUNCHED',
  Maintenance = 'MAINTENANCE',
  PreLaunch = 'PRE_LAUNCH',
  Hibernation = 'HIBERNATION'
}

export type Subscription = Node & {
  __typename?: 'Subscription';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
  variantId: Scalars['Int']['output'];
  product?: Maybe<Product>;
  quantity: Scalars['Int']['output'];
  frequencyInDays: Scalars['Int']['output'];
  countryId: Scalars['Int']['output'];
  status: SubscriptionStatus;
  shippingService?: Maybe<Scalars['String']['output']>;
  shipments?: Maybe<SubscriptionShipmentConnection>;
  shippingAddress?: Maybe<Address>;
  doorCode?: Maybe<Scalars['String']['output']>;
  initialOrderId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type SubscriptionShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  shippingStatuses?: InputMaybe<Array<InputMaybe<SubscriptionShipmentShippingStatus>>>;
};

export enum SubscriptionCancellationReason {
  TooExpensive = 'too_expensive',
  PreferFlexibleShopping = 'prefer_flexible_shopping',
  HardToManageOrEdit = 'hard_to_manage_or_edit',
  RatherShopElsewhere = 'rather_shop_elsewhere',
  SubscriptionNotSuitableRightNow = 'subscription_not_suitable_right_now',
  Other = 'other'
}

export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  pageInfo?: Maybe<PageInfo>;
  edges: Array<SubscriptionEdge>;
};

export type SubscriptionEdge = {
  __typename?: 'SubscriptionEdge';
  node: Subscription;
  cursor?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Paused = 'paused'
}

export type SubscriptionShipment = Node & {
  __typename?: 'SubscriptionShipment';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  subscriptionId: Scalars['Int']['output'];
  orderId?: Maybe<Scalars['Int']['output']>;
  shippingStatus: SubscriptionShipmentShippingStatus;
  paymentStatus: SubscriptionShipmentPaymentStatus;
  shippingDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionShipmentEdge = {
  __typename?: 'SubscriptionShipmentEdge';
  node: SubscriptionShipment;
  cursor?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionShipmentConnection = {
  __typename?: 'SubscriptionShipmentConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<SubscriptionShipmentEdge>>>;
};

export enum SubscriptionShipmentShippingStatus {
  Pending = 'pending',
  Processed = 'processed',
  Shipped = 'shipped',
  Cancelled = 'cancelled'
}

export enum SubscriptionShipmentPaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
  Failed = 'failed'
}

export type SwatchOptionValue = CatalogProductOptionValue & {
  __typename?: 'SwatchOptionValue';
  hexColors: Array<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};


export type SwatchOptionValueImageUrlArgs = {
  width: Scalars['Int']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
};

export type TextFieldOption = CatalogProductOption & {
  __typename?: 'TextFieldOption';
  entityId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type UrlField = {
  __typename?: 'UrlField';
  vanityUrl: Scalars['String']['output'];
  cdnUrl: Scalars['String']['output'];
};

export type Variant = Node & {
  __typename?: 'Variant';
  id: Scalars['ID']['output'];
  entityId: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  upc?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Measurement>;
  height?: Maybe<Measurement>;
  width?: Maybe<Measurement>;
  depth?: Maybe<Measurement>;
  options: OptionConnection;
  productOptions: ProductOptionConnection;
  defaultImage?: Maybe<Image>;
  campaign?: Maybe<Campaign>;
  prices?: Maybe<VariantPrices>;
  inventory?: Maybe<VariantInventory>;
  metafields: MetafieldConnection;
  isPurchasable?: Maybe<Scalars['Boolean']['output']>;
  lowest30DaysPrice?: Maybe<Scalars['BigDecimal']['output']>;
  bundle?: Maybe<Bundle>;
  units?: Maybe<Scalars['Int']['output']>;
};


export type VariantOptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VariantProductOptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VariantPricesArgs = {
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode?: InputMaybe<CurrencyCode>;
};


export type VariantMetafieldsArgs = {
  namespace: Scalars['String']['input'];
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantConnection = {
  __typename?: 'VariantConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<VariantEdge>>>;
};

export type VariantEdge = {
  __typename?: 'VariantEdge';
  node: Variant;
  cursor: Scalars['String']['output'];
};

export type VariantInventory = {
  __typename?: 'VariantInventory';
  aggregated?: Maybe<Aggregated>;
  isInStock: Scalars['Boolean']['output'];
  byLocation?: Maybe<LocationConnection>;
};


export type VariantInventoryByLocationArgs = {
  locationEntityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantPrices = Prices & {
  __typename?: 'VariantPrices';
  price: Money;
  salePrice?: Maybe<Money>;
  basePrice?: Maybe<Money>;
  promoPrice?: Maybe<Money>;
  pricePerKilo?: Maybe<Money>;
  pricePerLiter?: Maybe<Money>;
  pricePerUnit?: Maybe<Money>;
};

export type Video = {
  __typename?: 'Video';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};
